.App {
  text-align: center;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  opacity: 1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #F5F5F5;
}

.App-link {
  color: #098FAF;
  background-color: #098FAF;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  width: 100%;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 0px solid rgba(0, 0, 0, 0.8);
  padding: 30px;
  font-size: 30px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.grid-item-logo{
  border: 0px solid rgba(0, 0, 0, 0.8);
  padding: 30px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.HeaderBar{
  background-color: #098FAF;
}

.bottom{
  height: 100px;
  width:100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #E0E0E0 0% 0% no-repeat padding-box;
  opacity: 1;
}

body {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  padding: 0;
  margin: 0;
  background: #f8f9fa;
}
.header {
  background: #ffffff;
  padding: 20px 120px;
  border-bottom: 1px solid #f2f2f2;
  box-shadow: 0 0 5px 0 #f2f2f2;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px 60px;
}
.header .brand {
  
}
.header .brand > img {
  height: 44px;
}

.header .logo {
  
}
.header .logo > .title {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 4px;
  text-align: left;
}
.header .logo > img {
  height: 28px;
  margin-right: 16px;
}

@media only screen and (max-width: 993px) {
  .header {
      padding: 20px;
      justify-content: center;
  }
  .header .logo > img {
      height: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .header .logo > .title {
      text-align: center;
  }
}

 /* Move reCAPTCHA v3 badge to the left */
 
 .grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}